import Cookies from 'js-cookie'
const TokenKey = 'token'
const CTokenKey = 'token'

export function getToken() {
  // return sessionStorage.getItem(TokenKey)
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  sessionStorage.setItem(TokenKey, token)
  Cookies.set(TokenKey, token)
}

export function setCWebToken(token) {
  sessionStorage.setItem(CTokenKey, token)
  Cookies.set(CTokenKey, token)
}

export function sessionStorageGet(key) {
  return sessionStorage.getItem(key)
}

export function sessionStorageSet(key, value) {
  sessionStorage.setItem(key, value)
}

export function removeToken(token) {
  Cookies.set(TokenKey, '')
  if (token) {
    sessionStorage.removeItem(token)
  } else {
    sessionStorage.clear()
  }
}
