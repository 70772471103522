import router from './router'
import store from './store'
import { getToken } from '@/utils/auth' // 验权

router.beforeEach((to, from, next) => {
  // if (to.path !== '/login' && to.path !== '/' && to.path !== '/agreement/agreement-list' && to.path !== '/agreement/data-agreement' && to.path !== '/register') {
  //   localStorage.setItem('mFromFullPath', to.fullPath)
  //   localStorage.setItem('mFromPath', to.path)
  // }
  if (getToken()) {
    store.dispatch('getRoutes', getToken())
    if (to.path === '/login') {
      next('/home/index')
    }else{
      next()
    }
  } else {
    if (to.path === '/login' || to.path === '/') {
      next()
    } else {
      next('/login') // 否则全部重定向到登录页
      // next(`/login?redirect=${to.path}`) // 否则全部重定向到登录页
    }
  }
})