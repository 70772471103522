import Layout from '@/components/Layout'

const launchRoutes = [
  {
    path: '/launch',
    name: 'launch',
    redirect: '/launch/index',
    component: Layout,
    meta: {
        title: "启动页管理",
        icon: "el-icon-picture",
        show: true
    },
    children: [
      {
        path: '/launch/index',
        name: 'launchIndex',
        component: () => import(/* webpackChunkName: "about" */ '../../views/launch/index.vue'),
        meta: {
          show: false
        },
      }
    ]
  }
]

export default launchRoutes