import Layout from '@/components/Layout'

const entryRoutes = [
  {
    path: '/entryManagement',
    name: 'entryManagement',
    redirect: '/entryManagement/process',
    component: Layout,
    meta: {
        title: "入驻管理",
        icon: "el-icon-s-order",
        show: true
    },
    children: [
      {
        path: '/entryManagement/process',
        name: 'process',
        component: () => import(/* webpackChunkName: "about" */ '../../views/entryManagement/process.vue'),
        meta: {
          title: '入驻审核',
          icon: "sidebar-dashboard",
          show: true
        },
      },
      {
        path: '/entryManagement/entry',
        name: 'entry',
        component: () => import(/* webpackChunkName: "about" */ '../../views/entryManagement/entry.vue'),
        meta: {
          title: '入驻管理',
          icon: "sidebar-dashboard",
          show: true
        },
      }
    ]
  }
]

export default entryRoutes