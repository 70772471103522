import Layout from '@/components/Layout'

const appletRoutes = [
  {
    path: '/appletManagement',
    name: 'appletManagement',
    redirect: '/appletManagement/banner',
    component: Layout,
    meta: {
        title: "小程序管理",
        icon: "el-icon-s-platform",
        show: true
    },
    children: [
      {
        path: '/appletManagement/banner',
        name: 'banner',
        component: () => import(/* webpackChunkName: "about" */ '../../views/applet/index'),
        meta: {
          title: 'Banner',
          icon: "sidebar-dashboard",
          show: true
        },
      }
    ]
  }
]

export default appletRoutes