import Vue from 'vue'
import VueRouter from 'vue-router'
import homeRoutes from './modules/home'
import entryRoutes from './modules/entry'
import appletRoutes from './modules/applet'
import launchRoutes from './modules/launch'
import orderRoutes from './modules/order'




Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/login',
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "about" */ '../views/login/index.vue')
  },
  {
    path: '/403',
    component: () => import('@/views/403')
  }
]

const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
   return originalPush.call(this, location).catch(err => err)
}
// export const routes = constantRoutes.concat(debtorRouter, financingRouter, factoringRouter)
export const routesArr = routes.concat(homeRoutes, entryRoutes, appletRoutes, launchRoutes, orderRoutes)
const router = new VueRouter({
  routes: routesArr
})

export default router
