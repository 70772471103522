<template>
  <el-container>
    <el-header>
      <div class="name">
        <p>左左瑜伽管理系统</p>
        <p @click="isCollapse = !isCollapse">
          <i  v-if="isCollapse" class="el-icon-s-unfold"></i>
          <i v-else class="el-icon-s-fold"></i>
        </p>
      </div>
      <div class="operate">
        <!-- <i class="el-icon-s-tools"></i> -->
        <el-dropdown>
          <span class="user">
            <el-avatar :size="40" :src="userInfo.img"></el-avatar>
            <span class="userName">{{userInfo.username}} </span>
            <i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item> <span  @click="layoutFun()">退出</span></el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </el-header>
    <el-container>
      <Aside :isCollapse="isCollapse" />
      <el-main>
        <router-view />
      </el-main>
    </el-container>
  </el-container>
  <!-- <el-container>
    <el-aside width="200px">
      <Aside />
    </el-aside>
    <el-container>
      <el-header>左左瑜伽管理系统</el-header>
      <el-main>
        <router-view />
      </el-main>
    </el-container>
  </el-container> -->
</template>
<script>
import Aside from './aside.vue'
import {removeToken } from '@/utils/auth'
export default {
  name: 'Layout',
  components: {
    Aside
  },
  data() {
    return {
      userInfo: {},
      isCollapse: false
    }
  },
  created(){
    this.userInfo = JSON.parse(localStorage.getItem('user'))
  },
  methods:{
    layoutFun(){
      console.log(2132231)
      localStorage.removeItem('user')
      removeToken('token')
      this.$router.push('/login')
    }
  }
}
</script>
<style scoped lang="less">
.el-container {
  background: #f4f8ff;
  height: 100%;
  min-height: 100px;
  .el-header {
    background: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .name {
      color: #FF5C0F;
      font-size: 22px;
      font-weight: 500;
      display: flex;
      i{
        font-size: 28px;
        margin-left: 10px;
        cursor: pointer;
      }
    }
    .operate {
      display: flex;
      justify-content: space-around;
      align-items: center;
      .user{
        display: flex;
        align-items: center;
      }
      >div{
        margin-left: 30px;
        display: flex;
        align-items: center;
        .userName{
          padding: 0 10px;
        }
      }
    }
  }
  // .el-aside {
  //   background: #3d7fff;
  // }
  .el-main {
    overflow-x: hidden;
    margin: 20px;
    background: white;
    box-sizing: border-box;
  }
}
</style>
