import { routesArr } from '@/router'

/**
 * Filter asynchronous routing tables by recursion
 * @param routes asyncRoutes
 * @param roles
 */
export function filterAsyncRoutes(dynamicRoutes, rolesRouter) {
  const res = []
  dynamicRoutes.forEach(dynamic => {
    rolesRouter.forEach(roles => {
      if (roles.name === dynamic.name) {
        if (dynamic.children && roles.children) {
          dynamic.children = filterAsyncRoutes(dynamic.children, roles.children)
        }
        res.push(dynamic)
      }
    })
  })
  return res
}

const user = {
  state: {
    router: [],
    menu: [],
    user: {},
  },
  mutations: {
    SET_ROUTER: (state, data) => {
      state.router = data
    },
    SET_USER: (state, data) =>{
      state.user = data
    }
  },
  actions: {
    getRoutes({ commit }, data){
      let router = [{
        "name": "home",
        "path": "/home",
        "children": [{
          "name": "homeIndex",
          "path": "/home/index",
        }],
      },{
        "name": "entryManagement",
        "path": "/entryManagement",
        "children": [{
          "name": "process",
          "path": "/entryManagement/process",
        },{
          "name": "entry",
          "path": "/entryManagement/entry",
        }],
      },{
        "name": "appletManagement",
        "path": "/appletManagement",
        "children": [{
          "name": "banner",
          "path": "/appletManagement/banner",
        }],
      },{
        "name": "launch",
        "path": "/launch",
        "children": [{
          "name": "launchIndex",
          "path": "/launch/index",
        }],
      },{
        "name": "order",
        "path": "/order",
        "children": [{
          "name": "orderIndex",
          "path": "/order/index",
        }],
      }]
      let accessedRoutes = filterAsyncRoutes(routesArr, router)
      console.log(accessedRoutes)
      commit('SET_ROUTER', accessedRoutes)
    },
    getUser({commit},data){
      localStorage.setItem('user',JSON.stringify(data))
      commit('SET_USER', data)
    }
  },
}

export default user