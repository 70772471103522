<template>
  <el-menu
    :default-active="active"
    :collapse="isCollapse"
    class="el-menu-vertical-demo"
    @open="handleOpen"
    @close="handleClose"
    @select="handleSelect"
    background-color="#fff"
    text-color="#97A0C3"
    active-text-color="#FF5C0F"
  >
    <div v-for="(itme, index) in menu" :key="index">
      <el-submenu v-if="itme.children[0].meta.show" :index="itme.path" :class="itme.path == parentIndex?'is-parent-active':''">
        <template slot="title">
          <!-- <div class="border"></div> -->
          <div class="title-box">
            <i :class="itme.meta.icon"></i>
            <span slot="title">{{ itme.meta.title }}</span>
          </div>
        </template>
        <el-menu-item-group>
          <el-menu-item
            v-for="(itmes, indexs) in itme.children"
            :key="indexs"
            :index="itmes.path"
            >
          <div class="border"></div>
          <!-- <div class="title-box"> -->
            <i :class="itmes.meta.icon"></i>
            <span slot="title">{{ itmes.meta.title }}</span>
          <!-- </div> -->
            </el-menu-item
          >
        </el-menu-item-group>
      </el-submenu>
      <el-menu-item
        v-else
        :class="itme.path == parentIndex?'is-parent-active':''"
        :index="itme.children[0].path"
      >
        <div class="border"></div>
        <!-- <div class="title-box"> -->
          <i :class="itme.meta.icon"></i>
          <span slot="title">{{ itme.meta.title }}</span>
        <!-- </div> -->
      </el-menu-item>
    </div>
  </el-menu>
</template>
<script>
export default {
  data() {
    return {
      menu: this.$store.getters.router, // 路由表
      active: this.$route.path, // 选中index
      parentIndex: '' // 父index
    }
  },
  props: {
    isCollapse: {
      type:Boolean,
      default: true
    }
  },
  created() {
    this.handleSelect(this.active)
  },
  methods: {
    handleOpen(key, keyPath) {
    },
    handleClose(key, keyPath) {
    },
    handleSelect(key,keyPath) {
        console.log(key,keyPath)
      if(keyPath){
        this.parentIndex = '/' + keyPath[0].split('/')[1]
      }else{
        this.parentIndex = '/' + this.active.split('/')[1]
      }
      this.$router.push(key)
    },
  }
}
</script>
<style lang="less" scoped>
.el-menu {
  border: none;
//   background: white !important;
//   /deep/.el-submenu__title {
//     background: white !important;
//   }
//   /deep/ .el-submenu__title i {
//     color: #97A0C3;
//   }
//   /deep/ .el-menu-item i{
//     color: #97A0C3;
//   }
//   /deep/.el-submenu .el-menu-item {
//     background: white !important;
//   }
//   /deep/.el-menu-item {
//     background: white !important;
//   }

  /deep/ .is-parent-active>.el-submenu__title{
    color: #FF5C0F !important;
    i{
      color: #FF5C0F;
    }
  }
  .is-active{
    color: #FF5C0F !important;
    background: #FFEFE7 !important;
    >.border{
      width: 2px;
      height: 100%;
      background: #FF5C0F;
      position: absolute;
      left: 0;
      top: 50%;
      margin-top: -25px ;
    }
  }
  // /deep/.el-submenu .is-active{
  //   // font-weight: bolder;
  //   font-size: 16px;
  // }
//   /deep/.is-parent-active{
//     color: #97A0C3 !important;
//     background: #FFEFE7 !important;
//     // background: #FF5C0F !important;
//     .el-submenu__title{
//       display: flex;
//       .border{
//         width: 2px;
//         height: 100%;
//         background: #FF5C0F;
//         position: absolute;
//         left: 0;
//         top: 50%;
//         margin-top: -25px ;
//       }
//     }
//     span{
//       font-size: 18px;
//       // font-weight: bold;
//       color: #FF5C0F;
//     }
//     i{
//       color: #FF5C0F;
//     }
//   }
//   .title-box{
//     font-size: 16px;
//   }
}
/deep/.el-menu-item-group__title {
  padding: 0;
  margin: 0;
}

</style>
<style lang="less">
.el-menu:not(.el-menu--collapse) {
  width: 200px;
}
/*隐藏文字*/
  .el-menu--collapse  .el-submenu__title span{
    display: none;
  }
  /*隐藏 > */
  .el-menu--collapse  .el-submenu__title .el-submenu__icon-arrow{
    display: none;
  }
  
.el-menu--vertical {
  margin-left: 10px;
  position: relative;
  &::after {
    content: '';
    position: absolute;
    left: -3px;
    top: 13px;
    width: 0;
    height: 0;
    border-right: 15px solid #fff;
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
    z-index: 999;
  }

  & > .el-menu {
    background-color: #fff !important;
    padding: 16px 32px;
    a {
      display: block;
      margin: 0 0 15px 0;
      &:last-child {
        margin: 0;
      }
    }
    .svg-icon {
      width: 22px;
      height: 22px;
      vertical-align: middle;
      margin-right: 16px;
    }

    .el-menu-item {
      background-color: #fff !important;
      height: 30px;
      font-size: 14px;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      text-align: left;
      color: #74798c !important;
      line-height: 30px;
      &:hover {
        font-weight: 500;
        color: #3d7fff !important;
      }

      // &:focus {
      //   background-color: $menuFocus !important;
      // }

      &.is-active {
        font-size: 14px;
        font-weight: 400;
        font-family: PingFangSC, PingFangSC-Regular;
        color: #3d7fff !important;
      }
    }
  }
}
.el-menu--vertical > .el-menu .el-menu-item.is-active{
  color: #FF5C0F !important;
}
.el-menu--vertical > .el-menu .el-menu-item:hover{
  color: #FF5C0F !important;
}
.el-menu--vertical > .el-menu .el-menu-item{
  height: 40px !important;
}
.el-submenu__title:focus, .el-submenu__title:hover{  
  outline: 0 !important;  
  background: none !important;  
}  
.el-menu-item:hover{  
  outline: 0 !important;  
  background: white !important;
}  
.el-menu-item.is-active {  
  color: #fff !important;  
  background: #FF5C0F !important;  
} 
</style>
