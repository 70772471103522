import Layout from '@/components/Layout'

const homeRoutes = [
  {
    path: '/home',
    name: 'home',
    redirect: '/home/index',
    component: Layout,
    meta: {
        title: "用户管理",
        icon: "el-icon-s-custom",
        show: true
    },
    children: [
      {
        path: '/home/index',
        name: 'homeIndex',
        component: () => import(/* webpackChunkName: "about" */ '../../views/home/index.vue'),
        meta: {
          show: false
        },
      }
    ]
  }
]

export default homeRoutes